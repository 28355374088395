import React from "react";
import "./Button.css";
import { useFlags } from "launchdarkly-react-client-sdk";

function Button({
  type = "button",
  variant,
  size,
  style,
  leadIcon,
  tailIcon,
  children,
  onClick,
  disabled,
  className = "",
}) {
  const { platfromRebrandPhase1 } = useFlags();

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${variant} ${size} ${disabled ? "disabled" : ""} ${className}`}
      style={{
        ...style,
        ...(disabled && platfromRebrandPhase1 ? { backgroundColor: "#8239d099" } : {}),
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {leadIcon && <div>{leadIcon}</div>}
      {children}
      {tailIcon && <div>{tailIcon}</div>}
    </button>
  );
}

export default Button;

import {
  CUSTOMER_ONBOARDING_NAVIGATION_BY_SLUG,
  PASSWORD_VALIDATIONS_LIST,
} from "components/Screens/CustomerOnboarding/constants";

export const getValidationErrorList = ({ passwordValidationList = PASSWORD_VALIDATIONS_LIST, passwordInput = "" }) => {
  const updatedValidationList = passwordValidationList.map((validation) => {
    const { validate } = validation;
    const isChecked = validate(passwordInput);

    return {
      ...validation,
      isChecked,
    };
  });

  return updatedValidationList;
};

export const getAlertErrorMsg = (passwordMatch) => {
  if (!passwordMatch) {
    return "Password does not match";
  }
  return "Something Went Wrong";
};

export const validateConfirmPassword = ({ name, value = null, passwordData = {} }) => {
  const { newPassword = "", confirmPassword = "" } = passwordData;
  const isNonEmpty =
    value.trim() !== "" && (name === "confirmPassword" ? newPassword.trim() !== "" : confirmPassword.trim() !== "");
  const isMatch = name === "confirmPassword" ? value === newPassword : confirmPassword === value;

  return isNonEmpty && isMatch;
};

export const getCustomerOnboardingIncompleteStepRoute = (onboarding) => {
  const { status = "", steps = [] } = onboarding ?? {};

  if (status === "Pending") {
    const incompleteStep = steps.find((step) => step?.completed_at === null);

    const incompleteStepSlug = incompleteStep ? incompleteStep?.slug : null;
    if (incompleteStepSlug) {
      return CUSTOMER_ONBOARDING_NAVIGATION_BY_SLUG[incompleteStepSlug];
    }
    return null;
  }

  return null;
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import PayrollHistoryEmployeeBreakdown from "components/Screens/PayrollHistoryDetail/PayrollHistoryEmployeeBreakdown/index";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import {
  convertDate,
  convertDateToMonthAndYear,
  getMonthFromDate,
  handleRetryPayment,
  currencyFormat,
  getValue,
} from "components/Screens/CommonUtils";
import { usePermissions } from "components/Hook";
import Button from "components/Button/Button";
import PageLoader from "components/common/PageLoader";

import { RetryPayment } from "store/actions/Payment/RetryPayment";

import UnsuccessfulBanner from "assets/images/unsuccessfulBanner.png";
import SuccessfulBanner from "assets/images/successfulBanner.png";
import PendingBanner from "assets/images/pendingBanner.png";

import "./PayrollDetails.css";

const successChipData = {
  styles: { background: "#B4FFF2", padding: "1px 8px" },
  label: "Successful",
};
const failureChipData = {
  styles: {
    background: "#FAE6E8",
    padding: "1px 8px",
    color: "#DE0E22",
  },
  label: "Failed",
};
const processingChipData = {
  styles: {
    background: "#FAF4E6",
    padding: "1px 8px",
    color: "#B2840B",
  },
  label: "Processing",
};

function PayrollDetails({ getPayrollMonth }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loggedInUser } = usePermissions();
  const dispatch = useDispatch();
  const { platfromRebrandPhase1 } = useFlags();

  const retryPayment = useSelector((state) => state.RetryPayment);

  const [payrolBreakdown, setPayrollBreakdown] = useState({});

  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const handleGetPayrollBreakdown = async () => {
    const response = await getRequest(
      `${baseUrl}/customer/${loggedInUser?.customerId}/payment/${id}?limit=100&page=0`,
      true
    );

    setPayrollBreakdown(response?.payload);
    getPayrollMonth(getMonthFromDate(response?.payload?.paymentDate));
  };

  useEffect(() => {
    handleGetPayrollBreakdown();
  }, []);

  useEffect(() => {
    if (retryPayment.loadingRetryPayment === false && retryPayment?.retryPayment?.status === "payment in progress") {
      handleGetPayrollBreakdown();
    }
  }, [retryPayment]);

  const handleDownloadReceipt = async (customerPayrollId) => {
    try {
      const response = await getRequest(`${baseUrl}/customerReceipt/${loggedInUser?.customerId}/${customerPayrollId}`);
      window.open(response?.payload?.receiptUrl, "_blank");
    } catch (error) {
      console.log("here is error");
    }
  };

  const getBackgroundImage = (payrollBreakdown) => {
    if (payrollBreakdown.failure) {
      return UnsuccessfulBanner;
    }
    if (payrollBreakdown.status === "pending") {
      return PendingBanner;
    }
    return SuccessfulBanner;
  };

  const getBackgroundImageRebrand = (payrollBreakdown) => {
    if (payrollBreakdown.failure) {
      return "var(--color-primitives-danger-100)";
    }
    if (payrollBreakdown.status === "pending") {
      return "var(--color-primitives-warning-100)";
    }
    return "var(--color-primitives-primary-200)";
  };

  const getChipStyles = (payrollBreakdown) => {
    if (payrollBreakdown?.failure) {
      return failureChipData.styles;
    }
    if (payrollBreakdown?.status === "pending") {
      return processingChipData.styles;
    }
    return successChipData.styles;
  };

  const getChipLabel = (payrollBreakdown) => {
    if (payrollBreakdown?.failure) {
      return failureChipData.label;
    }
    if (payrollBreakdown?.status === "pending") {
      return processingChipData.label;
    }
    return successChipData.label;
  };

  return (
    <>
      {loadingAdditionalInfo && <PageLoader />}
      <div className="pd-container">
        <div
          className="pd-header"
          style={{
            background: platfromRebrandPhase1
              ? getBackgroundImageRebrand(payrolBreakdown)
              : `url(${getBackgroundImage(payrolBreakdown)})`,
          }}
        >
          <div className="pd-header-left">
            <Chip
              sx={{
                background: "#FCFCFF",
              }}
              label={convertDateToMonthAndYear(payrolBreakdown?.paymentDate, 0)}
            />

            <Typography
              className={`pd-header-title ${
                payrolBreakdown.status === "pending" || platfromRebrandPhase1
                  ? "pd-header-black-text"
                  : "pd-header-white-text"
              }`}
            >
              Cost to company
            </Typography>
            <p
              className={`pd-header-value ${
                payrolBreakdown.status === "pending" || platfromRebrandPhase1
                  ? "pd-header-black-text"
                  : "pd-header-white-text"
              }`}
            >
              $ {currencyFormat(getValue(payrolBreakdown?.totalPayroll))}
            </p>
          </div>
          {payrolBreakdown?.failure ? (
            <div className="pd-failure-btns-container">
              <Button
                variant="outlined"
                size="lg"
                id="changePhoto"
                onClick={() =>
                  handleRetryPayment(
                    loggedInUser?.customerId,
                    payrolBreakdown.customerPayrollId,
                    dispatch,
                    RetryPayment
                  )
                }
                style={{
                  alignSelf: "center",
                  color: platfromRebrandPhase1 ? "black" : "white",
                  border: "1px solid white",
                  marginRight: "12px",
                  ...(platfromRebrandPhase1 ? { background: "white" } : {}),
                }}
              >
                Retry Payment
              </Button>
              <Button
                variant="outlined"
                size="lg"
                id="changePhoto"
                onClick={() => navigate("/main/payment")}
                style={{
                  alignSelf: "center",
                  color: platfromRebrandPhase1 ? "black" : "white",
                  border: "1px solid white",
                  ...(platfromRebrandPhase1 ? { background: "white" } : {}),
                }}
              >
                Change Payment Method
              </Button>
            </div>
          ) : (
            payrolBreakdown.status !== "paid" && (
              <Button
                variant="primary"
                size="lg"
                id="changePhoto"
                style={{
                  alignSelf: "center",
                  color: platfromRebrandPhase1 ? "black" : "white",
                  border: "1px solid white",
                  ...(platfromRebrandPhase1 ? { background: "white" } : {}),
                }}
                onClick={() => handleDownloadReceipt(payrolBreakdown?.customerPayrollId)}
              >
                Download Reciept
              </Button>
            )
          )}
        </div>

        {payrolBreakdown?.failureReason && payrolBreakdown.failure ? (
          <div className="pd-failure-reason">{payrolBreakdown?.failureReason}</div>
        ) : (
          payrolBreakdown.status === "pending" && (
            <div className="pd-pending-reason">
              Great! We have re-initiated your payment and will confirm the receipt within 5 days.
            </div>
          )
        )}

        <div className="pd-payment-overview">
          <p className="pd-payment-overview-heading">Payment Overview</p>

          <div className="pd-payment-overview-details">
            <p>Payment Status</p>

            <Chip
              sx={{
                ...getChipStyles(payrolBreakdown),
                "&.MuiChip-root": {
                  borderRadius: "6px",
                  fontFamily: "Inter",
                  paddingInline: "0px",
                },
              }}
              label={getChipLabel(payrolBreakdown)}
            />
          </div>

          <div className="pd-payment-overview-details">
            <p>Payment Date</p>
            <p> {convertDate(payrolBreakdown?.paymentDate)}</p>
          </div>

          <div className="pd-payment-overview-details">
            <p>Paid On</p>
            <p> {convertDate(payrolBreakdown?.paidOn)}</p>
          </div>

          <div className="pd-payment-overview-details">
            <p>Total Employees paid</p>
            <p>{payrolBreakdown?.totalEmployees}</p>
          </div>

          <div className="pd-payment-overview-details">
            <p>Employee Salaries (before raise & bonuses)</p>
            <p>$ {currencyFormat(getValue(payrolBreakdown?.initialSalaries))}</p>
          </div>

          <div className="pd-payment-overview-details">
            <p>Raises added</p>
            <p>$ {currencyFormat(getValue(payrolBreakdown?.totalIncrement))}</p>
          </div>

          <div className="pd-payment-overview-details">
            <p>Bonus added</p>
            <p>$ {currencyFormat(getValue(payrolBreakdown?.totalBonus))}</p>
          </div>
        </div>

        <PayrollHistoryEmployeeBreakdown employeesBreakdown={payrolBreakdown?.details?.rows || []} />
      </div>
    </>
  );
}

export default PayrollDetails;

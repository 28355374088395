import React, { useEffect } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { USER_TYPES } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { GetPlatformConfig } from "store/actions/PlatformConfig";

function TermsAndPolicy({ userType, className = "" }) {
  const dispatch = useDispatch();
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  useEffect(() => {
    if (!platformConfig?.payload && !loadingplatformConfig) {
      dispatch(GetPlatformConfig());
    }
  }, []);

  return (
    <Grid className="mt-125">
      <Typography variant="body2" sx={{ fontFamily: "Poppins", fontSize: "12px" }} className={className}>
        By accessing the platform, you agree to abide by Edge’s{" "}
        <Link
          href={platformConfig?.payload?.TERMS_URL}
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: 500,
          }}
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          href={
            userType === USER_TYPES.EMPLOYEE
              ? platformConfig?.payload?.EMLOYEE_POLICY_URL
              : platformConfig?.payload?.CUSTOMER_POLICY_URL
          }
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: 500,
          }}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Grid>
  );
}

export default TermsAndPolicy;

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";
import { makeRequest } from "util/APIHelper";

export const getCustomerEmployeeList = async (userId) => {
  const { payload } = await getRequest(`${baseUrl}/customer/${userId}/employees/all`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const assignEmployeeToCustomerUsers = async (customerUserId, employeeIds) => {
  const body = { employeeIds };
  const request = `${baseUrl}/customerUsers/${customerUserId}/assignEmployees`;
  const { payload } = await postRequest(request, JSON.stringify(body));
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const getCustomerUserEmployees = async (customerUserId, limit, page, searchKeyword) => {
  let reqUrl = `${baseUrl}/customerUsers/${customerUserId}/employees?limit=${limit}&page=${page}`;
  if (searchKeyword) {
    reqUrl += `&searchKeyword=${searchKeyword}`;
  }
  const { payload } = await getRequest(reqUrl);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const customerChangePassword = async (customerUserId, body) => {
  const request = `${baseUrl}/customerUser/${customerUserId}/changePassword`;
  const { payload } = await postRequest(request, JSON.stringify(body));
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const customerChangePasswordV2 = async (body) => {
  const requestPath = `${baseUrl}/customerUser/changePassword`;
  const { payload } = await postRequest(requestPath, JSON.stringify(body));
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const customerUpdateGeneralInformation = async (body) => {
  const { payload } = await makeRequest({
    path: `${baseUrl}/customerUser/profile`,
    contentType: "application/json",
    method: "PATCH",
    body: JSON.stringify(body),
  });
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const customerOnboardingGetHelp = async (body) => {
  const requestPath = `${baseUrl}/customer/csHelp`;
  const { payload } = await postRequest(requestPath, JSON.stringify(body));
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
};

export const customerCreatePasswordApi = async (body) => {
  const request = `${baseUrl}/auth/setPassword`;
  const { payload } = await postRequest(request, JSON.stringify(body));
  return payload;
};

export const validateCustomerOnboardingTokenApi = async (body) => {
  const request = `${baseUrl}/auth/validateToken`;
  const { payload } = await postRequest(request, JSON.stringify(body));
  return payload;
};

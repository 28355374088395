import { s3ImagesBaseURL } from "util/APIBaseUrl";

import { CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE, CUSTOMER_ONBOARDING_PAYMENT_ROUTE } from "constants/routes";

export const MUST_BE_8_CHARACTERS = {
  id: "MUST_BE_8_CHARACTERS",
  message: "Must be at least 8 characters long",
  validate: (password = null) => password.length >= 8,
};

export const MIX_UPPER_AND_LOWER_CASE = {
  id: "MIX_UPPER_AND_LOWER_CASE",
  message: "Must include a mix of uppercase and lowercase characters",
  validate: (password = null) => /[a-z]/.test(password) && /[A-Z]/.test(password),
};

export const MUST_INCLUDE_NUMBERS = {
  id: "MUST_INCLUDE_NUMBERS",
  message: "Must include numbers",
  validate: (password = null) => /\d/.test(password),
};

export const MUST_INCLUDE_SPECIAL_CHARACTER = {
  id: "MUST_INCLUDE_SPECIAL_CHARACTER",
  message: "Must include special characters",
  validate: (password = null) => /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password),
};

export const CONFIRM_PASSWORD_VALIDATION = {
  id: "CONFIRM_PASSWORD_VALIDATION",
  message: "Password match",
  isChecked: null,
};

export const PASSWORD_VALIDATIONS_LIST = [
  MUST_BE_8_CHARACTERS,
  MIX_UPPER_AND_LOWER_CASE,
  MUST_INCLUDE_NUMBERS,
  MUST_INCLUDE_SPECIAL_CHARACTER,
];

export const USER_PASSWORD_INITIAL_VALUES = {
  newPassword: "",
  confirmPassword: "",
};

export const SLIDER_DATA = [
  {
    id: "slide-1",
    image: `${s3ImagesBaseURL}/slide-1.svg`,
    heading: "Effortless Payroll, Every Month",
    description: "Simplify your monthly payroll process and get back to what matters most—your business.",
  },
  {
    id: "slide-2",
    image: `${s3ImagesBaseURL}/slide-2.svg`,
    heading: "Effortlessly Manage Your Workforce",
    description: "Get real-time visibility into your workforce and stay organized—all in one platform.",
  },
  {
    id: "slide-3",
    image: `${s3ImagesBaseURL}/slide-3.svg`,
    heading: "Seamlessly Manage Time Off",
    description: "Track requests, approve leave, and maintain workforce balance with ease.",
  },
];

export const VALIDATE_TOKEN_CASES = {
  USED: "token-used",
  EXPIRED: "token-expired",
};

export const CUSTOMER_ONBOARDING_NAVIGATION_BY_SLUG = {
  "add-payment": CUSTOMER_ONBOARDING_PAYMENT_ROUTE,
  "calendly-meeting": CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE,
};

export const ONBOARDING_SIDEBAR_STEPS = [
  {
    label: "Confirm your payment",
    description: "Review your payment summary and provide your ACH details",
  },
  {
    label: "Schedule a kickoff call",
    description: "Connect with our team for a smooth start and any help you may need",
  },
];

export const ONBOARDING_GET_HELP_DROPDOWN_OPTIONS = [
  {
    value: "Payment information",
    label: "Payment information",
  },
  {
    value: "Kick-off call",
    label: "Kick-off call",
  },
  {
    value: "Other",
    label: "Other",
  },
];

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Grid, Typography, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { MailOutline } from "@mui/icons-material";

import Button from "components/common/Button";
import Slider from "components/common/Slider/Slider";
import ErrorsIndicatorList from "components/common/ErrorsIndicatorList/ErrorsIndicatorList";
import PageLoader from "components/common/PageLoader";
import TermsAndPolicy from "components/common/TermAndPolicy";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import { usePermissions } from "components/Hook";
import Text from "components/common/Text";

import {
  getAlertErrorMsg,
  getValidationErrorList,
  validateConfirmPassword,
} from "components/Screens/CustomerOnboarding/helpers";

import { ResetAuthUser, SetPasswordSuccess } from "store/actions/AuthUser";

import { validateCustomerOnboardingTokenApi, customerCreatePasswordApi } from "services/customer";

import {
  CONFIRM_PASSWORD_VALIDATION,
  PASSWORD_VALIDATIONS_LIST,
  SLIDER_DATA,
  USER_PASSWORD_INITIAL_VALUES,
  VALIDATE_TOKEN_CASES,
} from "components/Screens/CustomerOnboarding/constants";
import { USER_TYPES } from "constants/index";
import { CUSTOMER_ONBOARDING_PAYMENT_ROUTE, LINK_EXPIRED_ROUTE, LOGIN_ROUTE } from "constants/routes";

import edgeLogo from "assets/images/edge-logo.svg";

import "components/Screens/CustomerOnboarding/CreatePassword.scss";

function CreatePassword() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { loggedInUser, accessToken } = usePermissions();
  const { platfromRebrandPhase1, integratedContractSigning } = useFlags();

  const [openAlert, setOpenAlert] = useState(false);
  const [passwordData, SetPasswordData] = useState(USER_PASSWORD_INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [errorsListConfirmPass, setErrorsListConfirmPass] = useState([CONFIRM_PASSWORD_VALIDATION]);
  const [passwordValidationList, setPasswordValidationList] = useState(PASSWORD_VALIDATIONS_LIST);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const { email = " " } = customerInfo;
  const { isChecked: passwordsMatch = false } = errorsListConfirmPass[0];
  const isValid =
    passwordValidationList.every((err) => err.isChecked) && errorsListConfirmPass.every((err) => err.isChecked);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleErrors = (name, value) => {
    if (name === "newPassword") {
      const updatedValidationList = getValidationErrorList({ passwordInput: value });
      setPasswordValidationList(updatedValidationList);
    }

    setErrorsListConfirmPass([
      {
        ...CONFIRM_PASSWORD_VALIDATION,
        isChecked: validateConfirmPassword({ name, value, passwordData }),
      },
    ]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    handleErrors(name, value);
    SetPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e?.preventDefault();

    if (isValid) {
      setIsSubmitting(true);
      setOpenAlert(false);
      const { newPassword = "", confirmPassword = "" } = passwordData;
      const payload = {
        password: newPassword,
        confirmPassword,
        token,
      };
      const response = await customerCreatePasswordApi(payload);
      const { accessToken: accessTokenFromResponse = "" } = response;
      setIsSubmitting(false);

      if (!accessTokenFromResponse) {
        setOpenAlert(true);
        return;
      }

      dispatch(SetPasswordSuccess({ ...response }));
      navigateTo(CUSTOMER_ONBOARDING_PAYMENT_ROUTE);
    }
  };

  const validateToken = async () => {
    const { code, success, ...rest } = await validateCustomerOnboardingTokenApi({ token });
    setIsLoading(false);
    if (success === false && code === VALIDATE_TOKEN_CASES.USED) {
      const params = new URLSearchParams({
        error: VALIDATE_TOKEN_CASES.USED,
      });
      const finalUrl = `${LOGIN_ROUTE}?${params.toString()}`;
      navigateTo(finalUrl);
      return;
    }
    if (success === false && code === VALIDATE_TOKEN_CASES.EXPIRED) {
      navigateTo(LINK_EXPIRED_ROUTE);
      return;
    }

    setCustomerInfo({ ...rest });
  };

  useEffect(() => {
    if (token || accessToken) {
      const redirectUser = () => {
        if (accessToken && loggedInUser) {
          localStorage.clear();
          dispatch(ResetAuthUser({}));
        }
        validateToken();
      };

      redirectUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, accessToken]);

  if (!integratedContractSigning) {
    return null;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <Grid container justifyContent="space-evenly" className="change-password-main-container">
        <Grid
          container
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="email-password-main-container flex justify-center items-center"
        >
          <form onSubmit={onSubmit}>
            <div className="change-password-inner-container">
              <div className="pb-5">
                {platfromRebrandPhase1 ? (
                  <EdgeLogo size="md" state="dark" />
                ) : (
                  <Box component="img" className="edge-logo" alt="edge" src={edgeLogo} />
                )}
              </div>
              <div>
                <div className="heading-h1-semibold">Lets get you started with Edge</div>
                <div className="para-body-m-regular subheader-text">
                  Set up your password to begin your journey with us.
                </div>
              </div>
              <div className="mt-2">
                <div className="email-container">
                  <div className="email-icon-bg">
                    <MailOutline className="icon-color" />
                  </div>

                  <div className="flex flex-col justify-start">
                    <div className="heading-h5-regular label-color">Your email address</div>
                    <div className="para-body-m-medium email-color">{email}</div>
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <Text
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label="Password"
                  labelClass="label-color"
                  fullWidth
                  onChange={handleInputChange}
                  showPasswordEye
                  variant="outlined"
                />
              </div>

              <ErrorsIndicatorList errors={passwordValidationList} />

              <div className="mt-1">
                <Text
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  labelClass="label-color"
                  type="password"
                  fullWidth
                  onChange={handleInputChange}
                  showPasswordEye
                  variant="outlined"
                />
              </div>

              <ErrorsIndicatorList errors={errorsListConfirmPass} />

              <Grid item className="mt-1">
                {openAlert && (
                  <Alert
                    open={openAlert}
                    variant="filled"
                    onClose={handleAlertClose}
                    icon={false}
                    sx={{
                      backgroundColor: "var(--color-icon-danger)",
                      padding: "initial",
                      px: "10px",
                      textAlign: "center",
                      borderRadius: "5px !important",
                      color: "#ffffff !important",
                      "&.MuiPaper-root": {
                        color: "#ffffff !important",
                      },
                    }}
                  >
                    <Typography className="heading-h5-regular">{getAlertErrorMsg(passwordsMatch)}</Typography>
                  </Alert>
                )}
              </Grid>
              <div className="mt-1">
                <Button
                  type="submit"
                  id="creat-password"
                  variant="contained"
                  label="Create Password and Login"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  fullWidth
                  size="large"
                />
              </div>
              <TermsAndPolicy userType={USER_TYPES.CUSTOMER} className="subheader-text" />
            </div>
          </form>
        </Grid>
        <Grid container item xl={6} lg={6} md={6} sm={12} xs={12} className="slider-main-container">
          <Slider sliderData={SLIDER_DATA} />
        </Grid>
      </Grid>
    </>
  );
}

export default CreatePassword;
